import { remoteDB } from "../assets/js/pouchDB.js";
import { $user } from "../stores/user.ts";
import { useStore } from "@nanostores/solid";
import { dispatchForceSyncEvent } from "../assets/js/util.js";
import { Show } from "solid-js";
import { startCase } from "es-toolkit";

export default function Profile() {
	const user = useStore($user);
	// refs
	let loginModal: HTMLDialogElement;

	const attemptLogin = (event) => {
		event.preventDefault();

		const credentials = new FormData(event.target);

		remoteDB.logIn(
			credentials.get("username"),
			credentials.get("password"),
			function (err, response) {
				if (err) {
					if (
						err.name === "unauthorized" ||
						err.name === "forbidden"
					) {
						// name or password incorrect
						alert("Username or password is incorrect.");
					} else {
						// cosmic rays, a meteor, etc.
						alert(
							"An unexpected error has occurred. Please try again.",
						);
					}
				} else {
					$user.set({
						name: startCase(response.name),
						email: "",
					});

					dispatchForceSyncEvent();
					loginModal.close();
				}
			},
		);
	};

	remoteDB.getSession(function (err, response) {
		if (err) {
			// network error
		} else if (!response.userCtx.name) {
			// nobody's logged in
		} else {
			// response.userCtx.name is the current user
			$user.set({
				name: startCase(response.userCtx.name),
				email: "",
			});
		}
	});

	return (
		<Show
			when={!user().name}
			fallback={<span class="mt-2">Hi {user().name}!</span>}
		>
			<button
				class="button is-info mt-1"
				onClick={() => loginModal.showModal()}
			>
				Login
			</button>
			<dialog
				ref={loginModal}
				onClick={({ target }) =>
					target === loginModal && loginModal.close()
				}
				class="backdrop:bg-gray-500 backdrop:bg-opacity-80 z-50 rounded-sm text-center p-8"
			>
				<div class="flex flex-col justify-center gap-y-4">
					<form onSubmit={attemptLogin}>
						<div class="field">
							<label class="label">Username</label>
							<div class="control">
								<input
									class="input"
									type="text"
									placeholder="username"
									name="username"
									required
								/>
							</div>
						</div>
						<div class="field">
							<label class="label">Password</label>
							<div class="control">
								<input
									class="input"
									type="password"
									placeholder="password"
									name="password"
									required
								/>
							</div>
						</div>

						<div class="flex justify-around mt-4 gap-x-2">
							<button
								type="button"
								class="button"
								onClick={() => loginModal.close()}
							>
								Close&nbsp;
							</button>
							<button class="button is-success" type="submit">
								Login&nbsp;
							</button>
						</div>
					</form>
				</div>
			</dialog>
		</Show>
	);
}